<template>
  <span>
    <slot />
  </span>
</template>

<script lang="ts" setup>

</script>

<style>

</style>